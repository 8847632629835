import React, { Component } from 'react';

class Meetups extends Component {
  render() {
    return ( 
      <div>
        <h1>Meetups</h1>
      </div>
    );
	}
}

export default Meetups;