import React, { Component } from 'react';

class PageNotFound extends Component {
  render() {
    return ( 
      <div>
        <h1>PageNotFound</h1>
      </div>
    );
	}
}

export default PageNotFound;